.calendar-container {
    background-color: var(--primaryColorDark);
    color: var(--blueColor);
    font-size: 14px;
    padding: 30px 10px;
    position: absolute;
    width: 90%;
    max-width: 400px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 0%);
}

.calendar-button__container {
    display: flex;
    justify-content: flex-end;
    padding: 0px 10px;
}

.calendar-button {
    background-color: transparent; /* Green */
    border: none;
    color: white;
    padding: 10px 16px;
    display: inline-block;
    font-weight: 700;
}

:focus {
    outline: none !important;
}

.DayPicker {
    font-size: 14px;
    font-weight: 600;
}

.DayPicker-Day {
    width: 20px;
    padding: 0.7em;
}

.DayPicker-Day--outside {
    color: transparent;
    background-color: transparent;
    cursor: default;
}

.DayPicker:not(.DayPicker--interactionDisabled) .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
    background-color: var(--primaryColorDark);
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
    background-color: var(--blueColor);
}

.DayPicker-Day--today {
    color: var(--blueColor);
    font-weight: 500;
}

.DayPicker-Weekday {
    color: var(--primaryColorLight);
}

.DayPicker-WeekdaysRow {
    border-top-width: 1px;
    border-top-style: solid;
    border-top-color: var(--primaryColorLight);
}

.DayPicker-Day.DayPicker-Day--selected {
    background-color: var(--blueColor);
    color: var(--primaryColorDark);
}

.DayPicker-Day.DayPicker-Day--disabled {
    color: var(--primaryColorLight);
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
    background-color: var(--blueColor);
    color: var(--primaryColorDark);
}

.DayPicker-Day--selected.DayPicker-Day--outside {
    background-color: var(--primaryColorDark);
    color: var(--primaryColorDark);
}
