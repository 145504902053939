
.sticky-header {
    position: sticky;
    top: -70px;
    z-index: 100;
    background: var(--primaryColorBackground);
    text-align: -webkit-center;
}

.service-container--landscape {
    display: flex;
    flex-direction: row;
}

.scrollable-view {
    overflow-x: hidden; /* Disable horizontal scroll */
    height: 100%;
}

@media screen and (max-height: 415px) {
    .map {
        max-height: 400px;
    }

    .scrollable-view {
        padding-right: 70px;
        padding-left: 15px;
    }
    @media screen and (min-width: 800px) {
        .scrollable-view {
            padding-right: 100px;
            padding-left: 45px;
        }
    }
}

.map {
    padding: 0px 0px 15px 0px;
}

.loading-indicator {
    color: var(--primaryColorLight)
}

.MuiLinearProgress-colorPrimary {
    background-color: transparent !important;
}

.MuiLinearProgress-barColorPrimary {
    background-color: var(--blueColor) !important;
}

.MuiLinearProgress-root {
    height: 2px !important;
}

.pull-animation{
    top: -10px;
    width: 40px;
    height: 40px;
    position: absolute;
    z-index: 115;
    left: 50vw;
    margin-left: -33px;
}

.MuiCircularProgress-colorPrimary {
    color: var(--primaryColorLight) !important;
}



