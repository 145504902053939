.header {
    color: #6f7d9f;
    display: flex;
    font-size: 0.8rem;
    font-weight: 700;
    align-items: center;
    padding: 96px 6px 6px 6px;
    justify-content: space-between;
    height: 15px;
}

@media (min-height: 600px) and (max-height: 769px) {
    .header {
        padding: 116px 6px 6px 6px;
    }
}

@media (min-height: 770px) {
    .header {
        padding: 126px 6px 6px 6px;
    }
}

@media (min-width: 800px) {
    .header {
        padding-right: 30px;
        padding-left: 30px;
    }
}

.header__date-container {
    align-items: center;
    display: flex;
    padding: 6px;
    justify-content: space-between;
}

.header__date-container__date {
    padding: 6px;
}

.header__text {
    padding: 6px;
}

.header__date-container__icon {
    width: 12px;
    color: var(--primaryColorLight);
    margin-bottom: -1px;
}

.header__icon--info {
    width: 14px;
    color: var(--primaryColorLight);
    margin-bottom: -2px;
}