.App {
    font-family: 'Titillium Web', sans-serif;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-content: flex-start;
    /*padding: 0px 5px;*/
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

@supports (-webkit-touch-callout: none) {

}

.App-header {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    font-size: calc(10px + 2vmin);

}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
