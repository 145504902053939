.nav-bar {
    display: flex;
    font-size: 0.8rem;
    align-items: center;
    justify-content: space-between;
    padding: 15px 0px 10px 0px;
    max-width: 450px;
}

.nav-bar--landscape {
    display: none;
}

.nav-bar__service-icon {
    width: 70px;
    font-size: 0.8rem;
    text-transform: uppercase;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    cursor: pointer;
}

.nav-bar__service-icon--red {
    color: var(--redColor);
    padding: 2px;
}

.nav-bar__service-icon--purple {
    color: var(--purpleColor);
    padding: 2px;
}

.nav-bar__service-icon--blue {
    color: var(--blueColor);
    padding: 2px;
}

.nav-bar__service-icon--gray {
    color: var(--primaryColorLight);
    padding: 2px;
}

.nav-bar__service-icon-- {
    color: var(--primaryColorMedium);
    padding: 2px;
}

@media screen and (max-height: 430px) {

    .nav-bar {
        display: none;
    }

    .nav-bar--landscape {
        display: flex;
        flex-direction: column;
        font-size: 0.8rem;
        position: fixed; /* Fixed Sidebar (stay in place on scroll) */
        z-index: 101; /* Stay on top */
        right: 10px;
        overflow-x: hidden; /* Disable horizontal scroll */
        top: 50%;
        transform: translate(10%, -50%);

    }
    @media screen and (min-width: 800px) {
        .nav-bar--landscape {
            right: 40px;
        }
    }

    .nav-bar__service-icon--red {
        display: none;
    }

    .nav-bar__service-icon--blue {
        display: none;
    }

    .nav-bar__service-icon--gray {
        display: none;
    }

    .nav-bar__service-icon--purple {
        display: none;
    }

    .nav-bar__service-icon-- {
        display: none;
    }
}