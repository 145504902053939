@media screen and (min-width: 600px) {
    .map--phone {
        display: none;
    }
}

.map--phone {
    padding-top: 30px;
    justify-content: center;
    width: 100%;
}

@media screen and (max-width: 600px) {
    .map--tablet {
        display: none;
    }
}

.map--tablet {
    padding-top: 10px;
    justify-content: center;
    width: 90%;
}

@media screen and (max-height: 415px) {
    .map--tablet {
        max-height: 400px;
    }
}