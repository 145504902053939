.footer {
    padding: 30px 15px 0px 15px;
    align-items: center;
    justify-content: center;
    font-weight: 500;
}

@media screen and (min-width: 600px) and (max-height: 450px) {
    .footer {
        padding: 30px 0px 0px 0px;
    }
}

@media screen and (min-width: 800px) and (min-height: 600px) {
    .footer {
        padding: 30px 30px 0px 30px;
    }
}


.footer__key-symbols {
    width: 100%;
    font-family: TitilliumWeb-Regular, sans-serif;
    justify-content: center;


}

.footer__row {
    padding: 50px 0px 30px 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

@media screen and (max-height: 415px) {
    .footer__row {
        padding: 20px 0px 45px 15px;
    }
}

.footer__row__go-up-button {
    width: 44px;
}

@media screen and (min-width: 600px) {
    .footer__key-symbols--phone {
        display: none;
    }
}
.footer__key-symbols--tablet {
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
}
@media screen and (max-width: 600px) {
    .footer__key-symbols--tablet {
        display: none;
    }
}