.service-container {
    padding-bottom: 40px;
    justify-content: center;
}

@media (min-width: 600px) {
    .service-container {
        display: flex;
        flex-direction: row;
        padding-bottom: 30px;
    }

    .service-container__description {
        padding-top: 20px;
        padding-right: 10px;
        padding-left: 10px;
    }
}

@media (min-width: 1000px) {

    .service-container {
        max-width: 1200px;
        margin-right: auto;
        margin-left: auto;
    }
}

.service-container__circle {
    width: 100%;
    min-width: 230px;
    max-width: 500px;
    margin-right: auto;
    margin-left: auto;
}

.service-container__description {
    width: 100%;
    min-width: 350px;
    max-width: 500px;
    font-size: 14px;
    margin-right: auto;
    margin-left: auto;
}

.status-icon {
    width: 24px;
    margin-bottom: -2px;
}

.divider {
    height: 1px;
    background: rgba(255, 255, 255, 0.30);
    margin: 5px 15px 15px;
}

.service-title {
    font-size: 18px;
    text-align: left;
    padding-left: 10px;
}

.day-overview__title {
    font-size: 12px;
    font-weight: bolder;
    text-align: left;
}

.day-overview__row {
    display: flex;
    flex-direction: row;
    color: var(--primaryColorLight);
    padding-bottom: 15px;
}

.day-overview__row__element {
    display: flex;
    flex-direction: row;
    padding: 0px 8px 0px 0px;
    align-items: center;
}

.day__container {
    display: flex;
    flex-direction: row;
}

.day-desc__container {
    width: 100%;
}

.expand-icon {
    width: 80px;
}

.kpi-container {
    padding-bottom: 20px;
}

.kpi-container__title {
    text-align: left;
}

.kpi-container__desc {
    display: flex;
    flex-direction: row;
    text-align: left;
    justify-content: space-between;
    color: var(--primaryColorLight);
    padding-top: 5px;
}

.kpi-container__title__column-1 {
    width: 50%;
}
.kpi-container__title__column-2 {
    padding-right: 5px;
    width: 50%;
}

.kpi-container__title__column-text {
    padding: 0;
}

.kpi-container__message {
    border-left: solid;
    border-color: var(--redColor);
    padding: 0px 10px;
    margin-left: -13px;
    color: var(--redColor);
}
.text--red {
    color: var(--redColor);
}