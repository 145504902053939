.pop-up__container {
    background: var(--primaryColorLight);
    border-radius: 15px;
    position: fixed;
    width: 300px;
    height: 100px;
    margin-left: -150px;
    left: 50%;
    bottom: 50px;
    color: var(--primaryColorBackground);
    display: flex;
    flex-direction: column;
    opacity: 0.95;
}

@media all and (display-mode: standalone) {
    .pop-up__container {
        display: none;
    }
}
.pop-up__close-button {
    background: none;
    border: none;
    color: var(--primaryColorBackground);
    align-self: flex-end;
    padding: 0px 6px 0px 0px;
}
.pop-up__text {
    align-self: center;
    justify-self: center;
    margin-top: -10px;
}
.pop-up__icon {
    width: 14px;
    padding: 3px;
    border-radius: 3px;
    background: white;
    margin-bottom: -4px;
}
